
/* Container for the entire page */
.page-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    min-height: 100vh; /* Ensure full height */
    background-image: url('C:/Users/ARX56/OneDrive/Desktop/suplex ashia pvt/client/src/bancsuplex.jpg'); /* Background image */
    background-size: cover; /* Cover the entire page */
    background-position: center; /* Center the background image */
    background-repeat: no-repeat; /* Prevent the background from repeating */
    color: #333; /* Dark text color for contrast */
}

/* Navigation Bar Styles */
.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #732121; /* White background */
    padding: 15px 30px; /* Improved padding */
    color: #333; /* Dark text color for contrast */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow */
}

.navbar h1 {
    font-size: 1.4em; /* Slightly larger font size */
    margin: 0; /* Remove default margin */
    color: #388e3c; /* Green color for branding */
}

.navbar .nav-links {
    display: flex;
    gap: 15px; /* Increased gap between links */
}

.navbar a {
    color: #388e3c; /* Green color for links */
    text-decoration: none;
    font-size: 1em; /* Standard font size */
    padding: 8px 12px; /* Added padding for button-like appearance */
    border-radius: 4px; /* Rounded corners */
    transition: background-color 0.3s ease, color 0.3s ease; /* Smooth transitions */
}

.navbar a:hover {
    background-color: #c8e6c9; /* Light green background on hover */
    color: #fff; /* White text on hover */
}

/* Content Area */
.content {
    flex: 1; /* Take remaining space */
    overflow-y: auto; /* Scrollable if needed */
    padding: 20px; /* Improved padding */
}

/* Registration Form Container */
.registration-container {
    width: 100%;
    margin: 20px auto; /* Centered with margin */
    padding: 20px; /* Improved padding */
    background-color: #E0FFFF; /* Light green background */
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); /* Enhanced shadow for depth */
    z-index: 1;
}

/* Registration Form Styles */
.registration-form {
    margin: 20px auto;
    width: 60%;
    display: flex;
    flex-direction: column;
    gap: 0px; /* Improved gap between elements */
}

.form-section {
    background-color: #e5ffff; /* Light teal background for sections */
    padding: 1px; /* Improved padding */
    border-radius: 8px;
    border: 1px solid #05515d; /* Dark teal border */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05); /* Subtle shadow */
    width: 60%; /* Set the desired width (adjust as necessary) */
    max-width: 90%; /* Optional: limit the maximum width */
    margin: 0 auto; /* Center the form horizontally */
    z-index: 1;
}


.form-section h2 {
    margin-bottom: 10px; /* Improved margin */
    color: #008080;
    text-align: center;
    font-weight: bold;
    font-size: 0.9em; /* Slightly larger font size */
}

label {
    font-weight: bold;
    margin-bottom: 0px; /* Improved margin */
    display: block;
    color: #008080;
    font-size: 0.8em; /* Standard font size */
    gap: 0px; /* Improved gap between elements */
    margin-left: 20px;
    
}
label2 {
    margin-bottom: 5px; /* Improved margin */
    display: block;
    font-style: italic;
    font-size: 0.8em; /* Standard font size */
    margin-left: 20px;
    
}
label3 {
    margin-bottom: 5px; /* Improved margin */
    display: block;
    font-style: italic;
    font-size: 0.8em; /* Standard font size */
    margin-left: 120px;
}
select{
    width: 30%;
    padding: 3px; /* Improved padding */
    margin-bottom: 15px; /* Improved margin */
    border: 1px solid #9deae6;
    border-radius: 4px;
    background-color: #c6ece9; /* Light background */
    box-sizing: border-box;
    font-size: 0.9em; /* Standard font size */
    margin-left: 20px;
}

input {
    width: 40%;
    padding: 3px; /* Improved padding */
    margin-bottom: 15px; /* Improved margin */
    border: 1px solid #9deae6;
    border-radius: 4px;
    background-color: #c6ece9; /* Light background */
    box-sizing: border-box;
    font-size: 0.9em; /* Standard font size */
    margin-left: 20px;
}

input:focus, select:focus {
    border-color: #008080; /* Highlight on focus */
    outline: none; /* Remove default outline */
    box-shadow: 0 0 5px rgba(4, 198, 205, 0.5); /* Subtle shadow on focus */
}

button {
    padding: 5px 5px; /* Improved padding */
    width: 10%;
    background-color: #036f71;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 0.9em; /* Standard font size */
    transition: background-color 0.9s ease; /* Smooth transition */
    margin-left: '20px';
}

button:hover {
    background-color: #055b70; /* Darker green on hover */
}

/* Specific alignment for submit buttons */
.button-group {
    display: flex;
    justify-content: center;
    gap: 70px; /* Gap between buttons */
}

/* Style for the step indicator */
.step-indicator {
    text-align: center;
    font-weight: bold;
    margin: 15px 0; /* Improved margin */
    font-size: 1em; /* Standard font size */
    color: #072a34; /* Subtle color */
}

.branch-details {
    border: 1px solid #054044; /* Light gray border */
    border-radius: 4px; /* Slightly rounded corners */
    width: 40%;
    padding: 5px; /* Space inside the box */
    margin: 5px 0; /* Space around the box */
    background-color: #b9ecf0; /* White background color */
    font-size: 0.7em;
    margin-left: 20px;
}
.tick-mark {
    position: absolute; /* Absolute positioning */
    top: 10px; /* Position from the top */
    right: 10px; /* Position from the right */
    font-size: 18px; /* Font size for the tick mark */
    color: green; /* Color for the tick mark */
}
.underline {
    text-decoration: underline;
}
h3 {
    margin-left: 20px; /* Set left margin to 20px */
    color: #008080; /* Optional: Set text color */
    font-size: 1.2em; /* Optional: Adjust font size */
}